
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import { venueFilter } from '@/util/helper';

@Component
export default class UserFilter extends Vue {
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  public search: string = '';
  public selectedVenues: string[] = [];

  public onSearch() {
    this.$emit('search', this.search);
  }

  @Watch('selectedVenues')
  @Emit('filter')
  public onFilterChange() {
    return { venues: this.selectedVenues };
  }

  public filter(item: Venue, queryText: string): boolean {
    return venueFilter(item, queryText);
  }
}
